import { rg4js } from './raygun';

/**
 * Refreshes a Google Publisher Tag (GPT) ad slot, using Hubvisor if available.
 *
 * This function finds the ad slot associated with the given `elementId` and
 * refreshes it. If the Hubvisor integration is available, it triggers a
 * `distilled:refresh` event via Hubvisor before refreshing the slot with GPT.
 *
 * Otherwise, it refreshes the slot directly using GPT. This should be a fallback as we expect
 * Hubvisor to be available in most cases.
 *
 * @param {string} elementId - The ID of the ad slot element to refresh.
 *
 * @example
 * ```ts
 * hubvisorRefresh("dd_search_top");
 * ```
 */

const refreshAdSlot = (elementId: string, tag: string) => {
  const googletag = window.googletag;

  googletag?.cmd.push(() => {
    const adSlots = googletag.pubads().getSlots();
    const slot = adSlots.find(
      (slot: any) => slot.getSlotElementId() === elementId,
    );

    if (!slot) {
      rg4js('send', {
        error: new Error('Ad slot is undefined'),
        tags: [tag],
        customData: {
          message: `Ad slot: ${elementId} is undefined`,
        },
      });
    }

    googletag.pubads().refresh([slot]);
  });
};

const hubvisorRefresh = (elementId: string) => {
  const Hubvisor = window.Hubvisor;
  const googletag = window.googletag;

  googletag?.cmd.push(() => {
    if (Hubvisor) {
      Hubvisor('distilled:refresh', [elementId], () => {
        refreshAdSlot(elementId, 'hubvisorRefresh');
      });
    } else {
      refreshAdSlot(elementId, 'googleRefresh');
    }
  });
};

export { hubvisorRefresh };
