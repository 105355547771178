import styled from 'styled-components';

import { safeGet } from 'helpers/safeGet';
import {
  DFPProps,
  SizeMapping,
  StylesForAdSlotType,
  UnitProps,
} from 'components/Toolkit/DFP';
import { SmartSessionStorage } from 'helpers/smartSessionStorage';
import { RefObject } from 'react';
import { DFPManager } from '@dsch/react-dfp';
import { DataTargetingProps } from 'types';
import getConfig from 'next/config';
import { useDFP } from 'components/Toolkit/DFP/DFP.hook';

const {
  publicRuntimeConfig: {
    DFP_ENABLE_LAZY_LOADING_ADP = false,
    DFP_FETCH_MARGIN_PERCENT_ADP = '400',
    DFP_RENDER_MARGIN_PERCENT_ADP = '200',
    DFP_MOBILE_SCALING_ADP = '1.5',
    DFP_ENABLE_LAZY_LOADING_SRP = false,
    DFP_FETCH_MARGIN_PERCENT_SRP = '400',
    DFP_RENDER_MARGIN_PERCENT_SRP = '200',
    DFP_MOBILE_SCALING_SRP = '1.5',
  },
} = getConfig();

type minHeightMap = {
  viewWidth: number;
  minHeightObj: { width: number; height: number };
};

type minHeightMapArray = minHeightMap[];

export function viewportSorter(a: minHeightMap, b: minHeightMap) {
  const aViewWidth = a.viewWidth;
  const bViewWidth = b.viewWidth;

  if (aViewWidth > bViewWidth) {
    return 1;
  }

  if (aViewWidth < bViewWidth) {
    return -1;
  }

  return 0;
}

function getDfpTiling() {
  if (typeof window !== 'undefined') {
    if (SmartSessionStorage.get('adManagerTiling')) {
      return SmartSessionStorage.get('adManagerTiling');
    }

    let value = Math.floor(Math.random() * 10).toString();
    SmartSessionStorage.set('adManagerTiling', value);
    return value;
  }
}

export function getTargetingArguments(
  targetingArguments: DataTargetingProps['dfp'] = {},
  debugMode = false,
) {
  const updatedArgs = { ...targetingArguments, tiling: getDfpTiling() };
  if (debugMode) {
    return { ...updatedArgs, site: 'debugmode' };
  }
  return { ...updatedArgs, site: 'donedealclassifieds' };
}

// This ensures no unit is displayed until the first declaration of one
export function generateSizeMap(sizeMapping: SizeMapping[]) {
  return [
    ...sizeMapping,
    {
      viewport: [0, 0],
      sizes: [],
    },
  ];
}

const StyledAdSlot = styled.div<StylesForAdSlotType>`
  .adBox {
    text-align: ${({ textAlign = 'center' }) => textAlign};
    min-height: ${({ minHeight = '0' }) => minHeight};

    &:not(:empty) {
      > div:first-child {
        display: inline-block;
      }
    }
  }

  @media print {
    display: none;
  }
`;

interface AdPositionProps {
  padding?: string;
  margin?: string;
  justifyContent?: string;
}
const AdPosition = styled.div<AdPositionProps>`
  display: flex;
  justify-content: ${({ justifyContent = 'center' }) => justifyContent};
  padding: ${({ padding = '0' }) => padding};
  margin: ${({ margin = '0 auto' }) => margin};
`;

interface AdBackgroundStyledProps {
  minHeightMapArray: minHeightMapArray;
}
const AdBackground = styled.div<AdBackgroundStyledProps>`
  ${({ minHeightMapArray }) => {
    if (!minHeightMapArray) return '';
    const sortedMap = minHeightMapArray.sort(viewportSorter);
    return sortedMap.reduce((cssOutput, minHeightObj) => {
      // If the height is 0 it could be a fluid unit so we
      // allow it to full go width
      if (minHeightObj.minHeightObj.height === 0) {
        return `
          display: flex;
          width: 100%;
          >div{
            flex: 1;
          }
      `;
      }
      // Otherwise loop through and build the heights based on size map
      return `
      ${cssOutput}
      @media only screen and (min-width: ${minHeightObj.viewWidth}px) {
        min-height: ${minHeightObj.minHeightObj.height}px;
        display: flex;
        >div{
          flex: 1;
        }
      }
    `;
    }, '');
  }}
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

interface IDFPUnitEventProps {
  slotId: string;
  adElementRef: RefObject<HTMLDivElement>;
  event: {
    isEmpty: boolean;
  };
}

export interface IDFPUnitProps extends UnitProps {
  onSlotRender?: (event: IDFPUnitEventProps) => void;
}

export function DFPUnit({
  styles = {},
  sizeMapping,
  dfpDisabled,
  AdSlot,
  disableBackground = false,
  className,
  slotId,
  ...rest
}: IDFPUnitProps) {
  const { adUnitRef } = useDFP({ slotId });

  if (dfpDisabled) {
    return null;
  }

  const currentSizeMap = generateSizeMap(sizeMapping);

  const minHeightMapArray = currentSizeMap.reduce(
    (acc: minHeightMapArray, sizeMap) => {
      const [viewWidth] = sizeMap.viewport;
      const currentSizes = safeGet(sizeMap, ['sizes']);
      let minHeightObj = { width: 0, height: 0 };

      if (currentSizes) {
        minHeightObj = currentSizes.reduce(
          (
            targetHeightObj: { width: number; height: number },
            adSize: [number, number],
          ) => {
            const [width, height] = adSize;
            return height > targetHeightObj.height
              ? { width, height }
              : targetHeightObj;
          },
          minHeightObj,
        );
      }

      return [
        ...acc,
        {
          viewWidth,
          minHeightObj: {
            ...minHeightObj,
          },
        },
      ];
    },
    [],
  );

  return (
    <StyledAdSlot {...styles} className={className} ref={adUnitRef}>
      <AdPosition
        margin={styles.margin}
        padding={styles.padding}
        justifyContent={styles.justifyContent}
      >
        <AdBackground
          minHeightMapArray={disableBackground ? [] : minHeightMapArray}
        >
          <AdSlot sizeMapping={currentSizeMap} slotId={slotId} {...rest} />
        </AdBackground>
      </AdPosition>
    </StyledAdSlot>
  );
}

export function setDFPProps(
  { targetingArguments, dfpNetworkId, collapseEmptyDivs = true }: DFPProps,
  debugMode = false,
) {
  return {
    dfpNetworkId,
    collapseEmptyDivs,
    targetingArguments: getTargetingArguments(targetingArguments, debugMode),
  };
}

export function DFPRefresh(googletag: string, DFPManager: any) {
  if (googletag) {
    DFPManager.refresh();
  }
}

export function clearAdSlots() {
  DFPManager.getGoogletag().then(function (gt: any) {
    if (typeof gt.destroySlots === 'function') {
      gt.destroySlots();
    }
  });
}

export function refreshAdSlots() {
  DFPManager.getGoogletag().then(function (gt: any) {
    if (typeof gt.refresh === 'function') {
      gt.refresh();
    }
  });
}
export function DFPLoad(DFPManager: any) {
  DFPManager.load();
}

export const refreshAdUnits = (slotIds: string[]) => {
  try {
    if (typeof window !== 'undefined' && (window as any)?.googletag) {
      const adSlots = (window as any).googletag
        ?.pubads()
        ?.getSlots()
        ?.filter((slot: any) => slotIds.includes(slot?.getSlotElementId()));

      if (adSlots && adSlots.length > 0) {
        (window as any).googletag.pubads().refresh(adSlots);
      }
    }
  } catch (error) {
    console.error(`Ad units failed to refresh: ${error}`);
  }
};

const DFP_LAZY_LOAD_CONFIG_ADP = {
  lazyLoad: {
    fetchMarginPercent: parseInt(DFP_FETCH_MARGIN_PERCENT_ADP),
    renderMarginPercent: parseInt(DFP_RENDER_MARGIN_PERCENT_ADP),
    mobileScaling: parseFloat(DFP_MOBILE_SCALING_ADP),
  },
  singleRequest: false,
};

const DFP_LAZY_LOAD_CONFIG_SRP = {
  lazyLoad: {
    fetchMarginPercent: parseInt(DFP_FETCH_MARGIN_PERCENT_SRP),
    renderMarginPercent: parseInt(DFP_RENDER_MARGIN_PERCENT_SRP),
    mobileScaling: parseFloat(DFP_MOBILE_SCALING_SRP),
  },
  singleRequest: false,
};

export const getDFPLazyLoadConfigAdDetailsPage = () =>
  DFP_ENABLE_LAZY_LOADING_ADP ? DFP_LAZY_LOAD_CONFIG_ADP : {};

export const getDFPLazyLoadConfigSearchResultsPage = () =>
  DFP_ENABLE_LAZY_LOADING_SRP ? DFP_LAZY_LOAD_CONFIG_SRP : {};
