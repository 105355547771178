import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { HUBVISOR_SCRIPT_ENABLED } from 'utils';
import { hubvisorRefresh } from 'helpers/hubvisor';

type useDFPProps = {
  slotId: string;
};

const useDFP = ({ slotId }: useDFPProps) => {
  const innerHeight = globalThis?.innerHeight ? globalThis.innerHeight : '1000';
  const rootMargin = `${innerHeight}px`;

  const [adUnitRef, inView] = useInView({
    triggerOnce: true,
    rootMargin,
  });

  useEffect(() => {
    if (!HUBVISOR_SCRIPT_ENABLED || !inView) return;
    hubvisorRefresh(slotId);
  }, [inView, slotId]);

  return {
    adUnitRef,
  };
};

export { useDFP };
